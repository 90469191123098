import React from "react"
import {CoursePageFull} from "../components/course-pages/CoursePageFull"
import { graphql, StaticQuery } from "gatsby"
import { faChartBar, faClock, faCode, faUser } from "@fortawesome/free-solid-svg-icons"
import { SHARED_COURSE_CONTENT } from "../components/shared/courses-text"

export default class ReactCoreConcepts extends React.Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            react_core_concepts: file(relativePath: { eq: "courses/courses-logos/react-core-concepts.png" }) { ...thumb },
            carouselIntro1: file(relativePath: { eq: "courses/courses-carousel/react-cc-diagram.png" }) { ...imgTrainingBig },

            mode1: file(relativePath: { eq: "courses/_general/mode/mode-thumb-1.png" }) { ...thumb },
            mode2: file(relativePath: { eq: "courses/_general/mode/mode-thumb-2.png" }) { ...thumb },
            mode3: file(relativePath: { eq: "courses/_general/mode/mode-thumb-3.png" }) { ...thumb },


          }
        `}
        render={data => <CoursePageFull {...this.props}  data={data} course={COURSE_INFO}/> }
      />
    )
  }
}

export const COURSE_INFO = {
  theme: {
    separatorHeaderColor1: '#61dafb',
    separatorHeaderColor2: '#30b3d7',
    separatorColor1: '#61dafb',
    separatorColor2: '#30b3d7',
    buttonColor: '#30b3d7',
    panelBackground1: '#30b3d7',
    iconColor1: '#666',       // summary
    iconColor2: '#30b3d7',    // faq
    footerVisible: true,
    navbarVisible: true,
  },
  header: {
    titleSEO: 'Corso di formazione su React',
    title: 'REACT <br />CORE CONCEPTS (ES6)',
    shortTitle: 'REACT <br />CORE CONCEPTS (ES6)',
    subtitle: 'Un corso di formazione sulle funzionalità principali della libreria React, pattern, best practice e sulle interessanti novità introdotte da Javascript ES6',
    image: 'react_core_concepts',
    difficulty: 'Principiante',
    duration: '16 ore (2gg)'
  },
  description: [
    { title: 'INTRODUZIONE', text: `
      <a href="https://reactjs.org/" target="_blank"> React</a>, sviluppato da Facebook, è attualmente una delle librerie Javascript più utilizzate per lo sviluppo di Single Page Application. 
      <br /><br />In questo corso non solo si affronteranno passo-passo i costrutti principali della libreria, che si occupa della sola gestione della user interface, 
      ma si utilizzeranno pattern, librerie 3rd party e tecniche per la creazione di applicazioni multi-view, la comunicazione con REST API, gestione styling, form con validazioni e molto altro.
     `},
    /*    { title: 'DURATA', text: 'Il corso ha una durata variabile tra le 16 e le 24 ore, quindi 2 o 3 giornate: <br /><br />• 16 ore: il corso sarà intensivo, con un ritmo molto veloce e ci concentreremo sui contenuti <br/>• 24 ore: ritmo più lento, argomenti affrontati in modo più approfondito, gli studenti potrano eseguire alcune esercitazioni e configurare l\'ambiente di lavoro'},*/
    { title: 'OBIETTIVO DEL CORSO', text: `
      Durante questo corso saranno analizzate la maggior parte delle funzionalità offerte dalla libreria React e del suo ecosistema, per la creazione di componenti e Single Page Applications.
       
    `},
    { title: 'REQUISITI', text: 'Familiarità con almeno un linguaggio di programmazione.<br /> È preferibile, ma non indispensabile, aver già utilizzato in precedenza HTML, CSS e avere un po\' di confidenza nella manipolazione del DOM in Javascript.' },
  ],
  metadata: [

  ],
  carousels: {
    main: [
      {img: 'carouselIntro1'},
      /*{img: 'carouselIntro2'},*/
    ],
    reviews: null
  },
  videos: {
    teaser1: null
  },
  summary: [
    { icon: faClock, label: 'Durata:', value: '16 ore (2gg)' },
    { icon: faChartBar, label: 'Livello:', value: 'Pricinpianti' },
    { icon: faCode, label: 'Versione React:', value: '16.8.x' },
    { icon: faUser, label: 'Tipologia:', value: 'Corso in aula' },
  ],
  exampleLesson: {
    enabled: false,
    title: 'ESEMPIO LEZIONE',
    desc: 'Un semplice esempio in Angular per il caricamento di dati tramite REST API e la loro visualizzazione sfruttando alcuni degli strumenti offerti dal framework:',
    video1: 'UXEvvLJhOBQ',
    bgColor: '#30b3d7',
    items: [
      { text: 'Direttive del framework: ngIf e ngFor'},
      { text: 'HttpClient e comunicazione con REST API'},
      { text: 'Creazione custom types tramite l\'utilizzo di interfaccce'},
    ]
  },
  mode: null,
  program: {
    colLeft: [
      { type: 'title', value: 'JAVASCRIPT ES2015 (aka ES6)'},
      { value: 'Javascript ES2015'},
      { value: 'Compiler, Transpiler e tool: Babel, Typescript e Webpack'},
      { value: 'var vs let vs const: function scope vs block scope'},
      { value: 'Template Literals'},
      { value: 'Arrow syntax'},
      { value: 'Destructuring'},
      { value: 'Object.assign() e Object concise syntax'},
      { value: 'Rest e Spread operator'},
      { value: 'Immutabilità'},
      { value: 'Array methods: find, map, filter, reduce, some, every …'},
      { value: 'ES6 modules & imports'},
      { value: 'Promises e operazioni asincrone'},



      { type: 'title', value: 'Form' },
      { value: 'Form in React' },
      { value: 'Controlled vs Uncontrolled Forms' },
      { value: 'Gestire campi di input: text, select, checkbox' },
      { value: 'Valori di default' },
      { value: 'Validazioni e regular expression' },
      { value: 'Gestione eventi mouse e interazione utente' },

      { type: 'title', value: 'Styling' },
      { value: 'Inline styling', },
      { value: 'classi CSS dinamiche', },
      { value: 'CSS Modules', },
      { value: 'Utilizzo di "classnames" per la gestione dinamica delle classi CSS', },
      { value: 'Animazioni con CSS transitions', },



      { type: 'title', value: 'Liste e gestione dati' },
      { value: 'Creare e manipolare liste dinamiche di elementi', },
      { value: 'Utilizzo dell\'immutabilità' },
      { value: 'Liste con template condizionali', },

    ],
    colRight: [

      { type: 'title', value: 'React Components' },
      { value: 'Approccio Component-Based', },
      { value: 'Stateless vs Stateful components', },
      { value: 'Functional vs Class components', },
      { value: 'Manipolazione HTML tramite Virtual DOM e reconciliation', },
      { value: 'Gestire i valori di defaults delle proprietà', },
      { value: 'PropTypes validation', },
      { value: 'Passaggio proprietà in input', },
      { value: 'Comunicazione tra componenti', },
      { value: 'Componenti compositi', },
      { value: 'JSX e template condizionali', },
      { value: 'Tutte le fasi del ciclo di vita del componente', },
      { value: 'Immutabilità e ottimizzazione performance', },
      { value: 'Introduzione agli hooks dei functional components', },


      { type: 'title', value: 'Stateful Components' },
      { value: 'Differenze tra componenti stateful e stateless', },
      { value: 'Container vs Presentational components', },
      { value: 'Gestione stato in React', },
      { value: '1-way data flow', },
      { value: 'Creare progetti e componenti “Redux-ready”', },


      { type: 'title', value: 'Comunicazione con il server' },
      { value: 'Utilizzo servizi RESTful', },
      { value: 'Comunicazione REST API con "Axios"', },
      { value: 'Comunicazione REST API con "fetch"', },
      { value: 'Promises & utilizzo di async/await', },


      { type: 'title', value: 'React Router' },
      { value: 'Integrazione React Router DOM', },
      { value: 'Sviluppo applicazioni multi-view', },
      { value: 'Creazione menu di navigazione', },
      { value: 'Passaggio parametri', },
      { value: 'Gestione Nested routes', },

    ],
  },
  pricing: {
    price: null,
    priceOffer: null,
    priceOfferDescription: null,
    items: [
      { label: 'Corso di formazione on-site', extra: '16 ore (2gg)'},
      { label: 'Accesso ad Area educational', extra: 'Slide & Esercizi'},
      { label: 'Codice Sorgente', extra: 'Incluso'},
      { label: 'Attestato di partecipazione', extra: 'PDF formato A4'},
    ],
    button: {
      enabled: true,
      visible: true,
      buyLink: '/forms/contact-form?subject=1',
      buyText: 'RICHIEDI PREVENTIVO',
      target: '_self',
      scrollToID: ''
    },
    bg: null,
  },
  newsletter: {
    enabled: true,
    campaignFormUrl: 'https://fabiobiondi.us16.list-manage.com/subscribe/post?u=2478e04b9171b207e77c6cbc0&amp;id=85573d8b21',
  },
  actionButtons: {
    main: {
      enabled: true,
      visible: true,
      buyLink: null,
      buyText: 'RICHIEDI PREVENTIVO',
      target: '_scrollTo',
      scrollToID: '#pricingPanel'

    }
  },
  faq: SHARED_COURSE_CONTENT.faq,
}
